import { Link } from "gatsby"
import React from "react"
import logo from "../images/logo.png"
import logo1 from "../images/logo-hwcid1.png"
import logo2 from "../images/logo-hwcid2.png"

class Header extends React.Component {

  constructor(props) {
    super(props)
    this.district = props.district
    this.state = {
      menuActive: false,
      moreInfoActive: false,
    }

    //bind this
    this.toggleMenu = this.toggleMenu.bind(this)
    this.toggleMoreInfo = this.toggleMoreInfo.bind(this)
  }


  toggleMenu() {
    this.setState({'menuActive': !this.state.menuActive})
  }

  toggleMoreInfo() {
    this.setState({'moreInfoActive': !this.state.moreInfoActive})
  }
  //({ district }) => {
  render() {
    const slugTable = {
    "1": "hwcid-1",
    "2": "hwcid-2",
    }

    let Nav = (<></>)
      console.log(this.district)
    if( this.district === "1") {
      Nav = (
        <>
        <Link className="navbar-item" to={`/${slugTable[this.district]}`}>Home</Link>
        <Link className="navbar-item" to={`/${slugTable[this.district]}/bill-payment-information`}>Billing</Link>
        <Link className="navbar-item" to={`/${slugTable[this.district]}/documents`}>Documents</Link>
        <Link className="navbar-item" to={`/${slugTable[this.district]}/parks-and-trails`}>Parks &amp; Trails</Link>
        <Link className="navbar-item" to={`/${slugTable[this.district]}/frequently-asked-questions`}>FAQ</Link>
        <div id="about_us" className={`navbar-item has-dropdown is-hoverable ${this.state.aboutUsActive ? 'is-active':''}`}>
          <div className="navbar-link" onClick={this.toggleAboutUs}>About Us</div>
          <div className="navbar-dropdown">
            <Link className="navbar-item" to={`/${slugTable[this.district]}/committees`}>Committees</Link>
            <Link className="navbar-item" to={`/${slugTable[this.district]}/board-of-directors`}>Board of Directors</Link>
            <Link className="navbar-item" to={`/${slugTable[this.district]}/district-consultants`}>Consultants &amp; Vendors</Link>
            <Link className="navbar-item" to={`/${slugTable[this.district]}/projects`}>District Projects</Link>
          </div>
        </div>
        <div id="more_info" className={`navbar-item has-dropdown is-hoverable ${this.state.moreInfoActive ? 'is-active':''}`}>
          <div className="navbar-link" onClick={this.toggleMoreInfo}>More Info</div>
          <div className="navbar-dropdown">
            <Link className="navbar-item" to={`/${slugTable[this.district]}/alerts`}>Alerts</Link>
            <Link className="navbar-item" to={`/${slugTable[this.district]}/watering-restrictions`}>Watering Restrictions</Link>
            <Link className="navbar-item" to={`/${slugTable[this.district]}/smart-meters`}>Smart Meters</Link>
            <Link className="navbar-item" to={`/${slugTable[this.district]}/waste-collection`}>Waste Collection</Link>
            <Link className="navbar-item" to={`/${slugTable[this.district]}/election-information`}>Election Information</Link>
            <Link className="navbar-item" to={`/${slugTable[this.district]}/tax-information`}>Tax Information</Link>
            <Link className="navbar-item" to={`/${slugTable[this.district]}/useful-links`}>Useful Links</Link>
            <Link className="navbar-item" to={`/${slugTable[this.district]}/contact-us`}>Contact Us</Link>
          </div>
        </div>
        </>
      )
    } else if( this.district === "2") {
      Nav = (
        <>
        <Link className="navbar-item" to={`/${slugTable[this.district]}`}>Home</Link>
        <Link className="navbar-item" to={`/${slugTable[this.district]}/bill-payment-information`}>Billing</Link>
        <Link className="navbar-item" to={`/${slugTable[this.district]}/documents`}>Documents</Link>
        <Link className="navbar-item" to={`/${slugTable[this.district]}/parks-and-trails`}>Parks &amp; Trails</Link>
        <Link className="navbar-item" to={`/${slugTable[this.district]}/frequently-asked-questions`}>FAQ</Link>
        <div id="more_info" className={`navbar-item has-dropdown is-hoverable ${this.state.moreInfoActive ? 'is-active':''}`}>
          <div className="navbar-link" onClick={this.toggleMoreInfo}>More Info</div>
          <div className="navbar-dropdown">
            <Link className="navbar-item" to={`/${slugTable[this.district]}/watering-restrictions`}>Watering Restrictions</Link>
            <Link className="navbar-item" to={`/${slugTable[this.district]}/smart-meters`}>Smart Meters</Link>
            <Link className="navbar-item" to={`/${slugTable[this.district]}/waste-collection`}>Waste Collection</Link>
            <Link className="navbar-item" to={`/${slugTable[this.district]}/committees`}>Committees</Link>
            <Link className="navbar-item" to={`/${slugTable[this.district]}/board-of-directors`}>Board of Directors</Link>
            <Link className="navbar-item" to={`/${slugTable[this.district]}/district-consultants`}>Consultants &amp; Vendors</Link>
            <Link className="navbar-item" to={`/${slugTable[this.district]}/election-information`}>Election Information</Link>
            <Link className="navbar-item" to={`/${slugTable[this.district]}/tax-information`}>Tax Information</Link>
            <Link className="navbar-item" to={`/${slugTable[this.district]}/projects`}>Projects</Link>
            <Link className="navbar-item" to={`/${slugTable[this.district]}/useful-links`}>Useful Links</Link>
          </div>
        </div>
        <Link className="navbar-item" to={`/${slugTable[this.district]}/contact-us`}>Contact</Link>
        </>
      )
    } else {
      Nav = (
        <>
        </>
      )

    }
    return (
    <header>
    <div className={`mobile-menu ${this.state.menuActive ? 'active' : ''}`}>
      <div className="head-bar"></div>
      <div className="side-bar"></div>
      <nav>{Nav}</nav>
    </div>
    <div className="aside-left">
      <Link to={`/${slugTable[this.district]}`}>
        {this.district === "1" ? (
          <img className="logo" src={logo1} alt="HWCID 1" />
        ) : (
          <img className="logo" src={logo2} alt="HWCID 2" />
        )}
      </Link>
      <Link to="/"><small>Select District</small></Link>
    </div>
    <nav>{ Nav }</nav>
    <div className="aside-right" onClick={this.toggleMenu}>
        <div className={`menu ${this.state.menuActive ? 'active' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
    </header>
  )}
}


export default Header
