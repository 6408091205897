import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Header from "../components/header"
import TopHat from "../components/tophat"
import Footer from "../components/footer"
import Content from "../components/content"
import SEO from "../components/seo"
import logo from "../images/logo.png"
import showdown from 'showdown'
const converter = new showdown.Converter()

const BoardPage = ({data, pageContext}) => {
    console.log(data)
    let directors = data.graphAPI.district.directors
    return (
        <Layout>
            <SEO title="Board of Directors" />
            <TopHat />
            <div className="main">
                <Header district={pageContext.district}  />
                <div className="main-content">
                    <div className="title-section">
                        <small style={{display: 'inline-block',paddingBottom: '1rem'}}>Hays WCID {pageContext.district}</small>
                        <h1 className="title is-size-1">Board of Directors</h1>
                    </div>
                    <div className="content-section">
                    {directors.map(director => {
                        let content = converter.makeHtml(director.content)
                        return (
                        <div key={director.id}>
                        {/* <div className="card" style={{ marginBottom: '1rem'}}> */}
                    <p  className=""><span style={{textTransform: 'uppercase'}}>{director.Role}</span><br /><strong className="is-size-4">{director.Name}</strong><br /><em><small className="has-text-grey">Term: <span style={{ fontSize: '0.8rem' }}>{director.Term}</span></small></em></p>
                            <div dangerouslySetInnerHTML={{__html: content}} />
                            <hr />
                        {/* </div> */}
                        {/* </div> */}
                        </div>
                    )})}
                    </div>
                </div>
                <Footer district={pageContext.district}></Footer>
            </div>
        </Layout>
    )
}

export default BoardPage

export const query = graphql`
    query boardPageById($district: ID!) {
        graphAPI {
            district(id: $district) {
                directors(sort: "order:asc") {
                    id
                    Name
                    Role
                    Term
                    order
                    content
                }
            }
        } 
    }
`


