import { Link } from "gatsby"
import React from "react"
import logo from "../images/logo.png"

class TopHat extends React.Component {

  render() {
    return (
    <div className="tophat">
      <div className="tophat-nav">
        <Link to="/">Select District</Link>
      </div>
    </div>
  )}
}


export default TopHat
